import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Carousel, Col, Container, Row } from "react-bootstrap";
import { MdCached } from "react-icons/md";
import AdvCard from "../components/AdvCard";
import BlogSection from '../components/BlogSection';

import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import "swiper/css";
import "swiper/css/autoplay";
import NavPagination from '../components/NavPagination';
import City from '../components/utils/City';
import { declOfNum, getImageURL } from '../helpers/all';
import { getArticles } from '../services/article';
import { getCategory } from '../services/category';
import { getFavorites, toggleFavorite } from '../services/favorite';
import Loader from '../components/utils/Loader';
import Select from '../components/utils/Select';

const Catalog = () => {
    const { id } = useParams();
    const [currentPage, setCurrentPage] = useState(1);
    const [sort, setSort] = useState();
    const isAuth = useSelector((state) => state?.auth.isAuth);
    const [prevId, setPrevId] = useState();
    const [prevSort, setPrevSort] = useState();
    const {
        control,
        register,
        formState: { errors, isValid },
        handleSubmit,
        reset,
        setValue,
    } = useForm({
        mode: "onChange",
        reValidateMode: "onSubmit",

    });
    const [articles, setArticles] = useState({
        loading: true,
        items: [],
    });

    useEffect(() => {
        setArticles((prev) => ({ ...prev, loading: true }))
        getArticles({ size: 4 })
            .then((res) => {
                setArticles((prev) => ({
                    prev,
                    loading: false,
                    ...res,
                }))
            })
            .catch(() => setArticles((prev) => ({ ...prev, loading: false })));


    }, []);

    const data = useWatch({ control });
    const [category, setCategory] = useState({ items: [], loading: true });
    useEffect(() => {
        if (id !== prevId || sort !== prevSort) {
            if (currentPage == 1) {
                getPage();
            }
            else {
                setCurrentPage(1); // Установка currentPage в 1 при изменении id или sort
            } // Установка currentPage в 1 при изменении id или sort
        }
        else {
            getPage();
        }
        // Сохраняем предыдущие значения id и sort
        setPrevId(id);
        setPrevSort(sort);
    }, [id, sort, currentPage]);


    const onPageChange = (page) => {
        setCurrentPage(page.selected + 1);
    };

    const getPage = (data) => {

        const filteredData = data ? Object.fromEntries(
            Object.entries(data).filter(([key]) => key !== 'options')
        ) : [];
        setCategory(prev => ({ ...prev, loading: true }));
        getCategory({ ...filteredData, id, currentPage, sort: sort, size: 36 })
            .then((res) => {
                const paramsTree = res?.category?.params && res?.category?.params.length > 0 ? res.category.params.map(param => {
                    // Проверяем, есть ли options у текущего параметра
                    if (param?.options) {
                        // Создаем дерево из options для текущего параметра
                        const optionsTree = createTree(param.options, 'id', 'parent', null).sort((a, b) => a.priority - b.priority);

                        // Возвращаем объект с данными параметра и его деревом options
                        return {
                            ...param,
                            children: optionsTree // Добавляем дерево options как children
                        };
                    } else {
                        // Если нет options, возвращаем просто сам параметр
                        return param;
                    }
                }) : null;

                setCategory(prev => ({
                    ...prev,
                    items: res,
                    params: paramsTree,
                    loading: false
                }));
                window.scrollTo(0, 0);
            })
            .catch(() => setCategory(prev => ({ ...prev, loading: false })));
    };

    const onSubmit = data => {
        if (currentPage == 1) {
            getPage(data);
        }
        else {
            setCurrentPage(1); // Установка currentPage в 1 при изменении id или sort
        }
    };

    const createTree = (data, idProp, parentProp, parentId) =>
        data
            .filter((n) => parentId === (n[parentProp] ?? null))
            .map((n) => ({
                ...n,
                children: createTree(data, idProp, parentProp, n[idProp]),
            }));

    const favorites = useSelector((state) => state.favorite.items);
    const dispatch = useDispatch();
    useEffect(() => {
        reset();
        if (isAuth) {
            dispatch(getFavorites());
        }
    }, [id]);
    const onFav = useCallback((e) => {
        dispatch(toggleFavorite({ productId: e, }));
    }, []);
    const declension = declOfNum(category?.items?.products?.pagination?.totalItems, ['предложение', 'предложения', 'предложений']);
    if (category.loading || articles.loading) {
        return <Loader full />;
    }
    return (
        <main>
            <Container>
                <section className='py-4 py-sm-5 mb-5'>
                    <h1 className='h2'>{category?.items?.category?.title}</h1>


                    <div className='filter'>
                        <div className='filter-top'>
                            {/* <button type='button'>скрыть поиск</button> */}
                            <h6>Поиск по параметрам</h6>
                            <div>(Найдено: {category?.items?.products?.pagination?.totalItems})</div>
                        </div>
                        <div className='filter-main'>
                            <Row className='gx-4 gx-xxl-5 gy-4 mb-2'>
                                {category?.params && category?.params?.length > 0 && category?.params.map((item) => (
                                    <Col xs={12} md={6} lg={4} key={item.id}>
                                        <Select
                                            className='w-100'
                                            value={data?.param}
                                            title={"Выберите сферу"}
                                            onClick={(e) => {
                                                if (e.value == "false") {
                                                    const newData = { ...data };
                                                    delete newData.param;
                                                    delete newData.options;
                                                    delete newData.option;
                                                    reset({ ...newData });
                                                }
                                                else {
                                                    setValue(`param`, e.value)
                                                    setValue(`options`, e.data)

                                                }
                                            }}
                                            data={[
                                                { value: "false", title: "Все сферы", data: null },
                                                ...(item?.children?.map((e) => ({
                                                    value: e.id,
                                                    data: e,
                                                    title: e.title,
                                                })) || [])
                                            ]}
                                        />
                                    </Col>

                                ))}
                                {data?.options &&
                                    <Col xs={12} md={6} lg={4} key={data?.options.id}>
                                        <Select
                                            title="Выберите раздел"
                                            className='w-100'
                                            value={data?.option}
                                            onClick={(e) => {
                                                if (e.value == "false") {
                                                    const newData = { ...data };
                                                    delete newData.option;
                                                    reset({ ...newData });
                                                }
                                                else {
                                                    setValue(`option`, e.value)
                                                }
                                            }}
                                            data={[
                                                { value: "false", title: "Все разделы" },
                                                ...(data?.options?.children?.map((e) => ({
                                                    value: e.id,
                                                    title: e.title,
                                                })) || [])
                                            ]}
                                        />
                                    </Col>
                                }

                                <Col xs={12} md={6} lg={4}>
                                    <City setCity={(e) => setValue("data.city", e)} city={data?.data?.city} defaultValue={data?.data?.city ?? ""} />
                                </Col>
                                {(id == 5 || id == 4) &&
                                    <Col xs={12} md={6} lg={4}>
                                        <Select
                                            title="Выберите стадию проекта"
                                            className='w-100'
                                            value={data?.data?.projectStage}
                                            onClick={(e) => {
                                                if (e.value == "false") {
                                                    const newData = { ...data };
                                                    delete newData.data.projectStage;
                                                    reset({ ...newData });
                                                }
                                                else {
                                                    setValue("data.projectStage", e.value)
                                                }
                                            }}
                                            data={[
                                                { value: "false", title: "Все стадии проекта" },
                                                { value: "Идея", title: "Идея" },
                                                { value: "В процессе создания", title: "В процессе создания" },
                                                { value: "Действующий бизнес", title: "Действующий бизнес" },
                                            ]}
                                        />
                                    </Col>
                                }
                                {(id == 2 || id == 5 || id == 4) &&
                                    <Col xs={12} md={6} lg={4}>
                                        <Select
                                            title="Выберите срок окупаемости"
                                            className='w-100'
                                            value={data?.data?.payback}
                                            onClick={(e) => {
                                                if (e.value == "false") {
                                                    const newData = { ...data };
                                                    delete newData.data.payback;
                                                    reset({ ...newData });
                                                }
                                                else {
                                                    setValue("data.payback", e.value)
                                                }
                                            }}
                                            data={[
                                                { value: "false", title: "Все сроки окупаемости" },
                                                { value: "до 6 месяцев", title: "До 6 месяцев" },
                                                { value: "от 6 месяцев до 1 года", title: "От 6 месяцев до 1 года" },
                                                { value: "от 1 до 3 лет", title: "От 1 до 3 лет" },
                                                { value: "от 3 до 6 лет", title: "От 3 до 6 лет" },
                                                { value: "от 6 лет и выше", title: "От 6 лет и выше" },
                                            ]}
                                        />
                                    </Col>
                                }
                                {id == 1 &&
                                    <Col xs={12} md={6} lg={4}>
                                        <Select
                                            title="Выберите срок окупаемости"
                                            className='w-100'
                                            value={data?.data?.paybackPeriodFrom}
                                            onClick={(e) => {
                                                if (e.value == "false") {
                                                    const newData = { ...data };
                                                    delete newData.data.paybackPeriodFrom;
                                                    reset({ ...newData });
                                                }
                                                else {
                                                    setValue("data.paybackPeriodFrom", e.value)
                                                }
                                            }}
                                            data={[
                                                { value: "false", title: "Все сроки окупаемости" },
                                                { value: "до 6 месяцев", title: "До 6 месяцев" },
                                                { value: "от 6 месяцев до 1 года", title: "От 6 месяцев до 1 года" },
                                                { value: "от 1 до 3 лет", title: "От 1 до 3 лет" },
                                                { value: "от 3 до 6 лет", title: "От 3 до 6 лет" },
                                                { value: "от 6 лет и выше", title: "От 6 лет и выше" },
                                            ]}
                                        />
                                    </Col>
                                }

                            </Row>
                            <Row className='gx-4 gx-xxl-5 gy-4 mb-2'>

                                <Col xs={12} lg={6}>
                                    <p className='mb-2'>Содержит слова:</p>
                                    <input
                                        type="text"
                                        placeholder='Введите поисковую фразу'
                                        value={data?.search ?? ""}
                                        onChange={g => setValue(`search`, g.target.value)}
                                    />
                                </Col>
                                {id == 1 &&
                                    <Col xs={12} md={6} lg={3}>
                                        <div>
                                            <p className='mb-2'>Объем инвестиций, руб.:</p>
                                            <div className='d-flex align-items-center'>
                                                <span className='me-2'>от</span>
                                                <input
                                                    type="number"
                                                    className='me-3'
                                                    value={data?.data?.startingInvestmentsFrom?.min ?? ""}
                                                    onChange={g => setValue(`startingInvestmentsFrom.min`, parseInt(g.target.value))}
                                                />
                                                <span className='me-2'>до</span>
                                                <input
                                                    type="number"
                                                    value={data?.data?.startingInvestmentsFrom?.max ?? ""}
                                                    onChange={g => setValue(`startingInvestmentsFrom.max`, parseInt(g.target.value))}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                }
                                {(id == 5 || id == 4) &&
                                    <Col xs={12} md={6} lg={3}>
                                        <div>
                                            <p className='mb-2'>Объем инвестиций, руб.:</p>
                                            <div className='d-flex align-items-center'>
                                                <span className='me-2'>от</span>
                                                <input
                                                    type="number"
                                                    className='me-3'
                                                    value={data?.requiredInvestments?.min ?? ""}
                                                    onChange={g => setValue(`requiredInvestments.min`, parseInt(g.target.value))}
                                                />
                                                <span className='me-2'>до</span>
                                                <input
                                                    type="number"
                                                    value={data?.requiredInvestments?.max ?? ""}
                                                    onChange={g => setValue(`requiredInvestments.max`, parseInt(g.target.value))}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                }
                                {id == 3 &&
                                    <Col xs={12} md={6} lg={3}>
                                        <div>
                                            <p className='mb-2'>Объем инвестиций, руб.:</p>
                                            <div className='d-flex align-items-center'>
                                                <span className='me-2'>от</span>
                                                <input
                                                    type="number"
                                                    className='me-3'
                                                    value={data?.possibleInvestments?.min ?? ""}
                                                    onChange={g => setValue(`possibleInvestments.min`, parseInt(g.target.value))}
                                                />
                                                <span className='me-2'>до</span>
                                                <input
                                                    type="number"
                                                    value={data?.possibleInvestments?.max ?? ""}
                                                    onChange={g => setValue(`possibleInvestments.max`, parseInt(g.target.value))}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                }
                            </Row>
                            <Row className='gx-4 gx-xxl-5 gy-4 '>
                                {id == 2 &&
                                    <>
                                        <Col xs={12} md={6} lg={3}>
                                            <div>
                                                <p className='mb-2'>Стоимость бизнеса:</p>
                                                <div className='d-flex align-items-center'>
                                                    <span className='me-2'>от</span>
                                                    <input
                                                        type="number"
                                                        className='me-3'
                                                        value={data?.businessCost?.min ?? ""}
                                                        onChange={g => setValue(`businessCost.min`, parseInt(g.target.value))}
                                                    />
                                                    <span className='me-2'>до</span>
                                                    <input
                                                        type="number"
                                                        value={data?.businessCost?.max ?? ""}
                                                        onChange={g => setValue(`businessCost.max`, parseInt(g.target.value))}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} md={6} lg={3}>
                                            <div>
                                                <p className='mb-2'>Чистая прибыль, руб.:</p>
                                                <div className='d-flex align-items-center'>
                                                    <span className='me-2'>от</span>
                                                    <input
                                                        type="number"
                                                        className='me-3'
                                                        value={data?.netProfit?.min ?? ""}
                                                        onChange={g => setValue(`netProfit.min`, parseInt(g.target.value))}
                                                    />
                                                    <span className='me-2'>до</span>
                                                    <input
                                                        type="number"
                                                        value={data?.data?.netProfit?.max ?? ""}
                                                        onChange={g => setValue(`netProfit.max`, parseInt(g.target.value))}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} md={6} lg={3}>
                                            <div>
                                                <p className='mb-2'>Оборот в месяц, руб.:</p>
                                                <div className='d-flex align-items-center'>
                                                    <span className='me-2'>от</span>
                                                    <input
                                                        type="number"
                                                        className='me-3'
                                                        value={data?.data?.turnoverPerMonth?.min ?? ""}
                                                        onChange={g => setValue(`turnoverPerMonth.min`, parseInt(g.target.value))}
                                                    />
                                                    <span className='me-2'>до</span>
                                                    <input
                                                        type="number"
                                                        value={data?.data?.turnoverPerMonth?.max ?? ""}
                                                        onChange={g => setValue(`turnoverPerMonth.max`, parseInt(g.target.value))}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </>
                                }

                                <Col md={12} className='d-flex justify-content-end'>
                                    <button type='reset' onClick={() => { reset(), getPage() }}>
                                        <MdCached className='fs-13 me-2' />
                                        <span>Очистить фильтр</span>
                                    </button>
                                    <button type='submit' className='btn-2 fw-bold ms-5' onClick={handleSubmit(onSubmit)}>Показать</button>
                                </Col>
                            </Row>
                        </div>
                    </div>

                    <nav className="catalog-nav">
                        <NavPagination totalPages={category?.items?.products?.pagination?.totalPages} onPageChange={onPageChange} currentPage={currentPage} />
                        {category?.items?.products?.pagination?.totalItems <= 36 ? <p>Показано {category?.items?.products?.pagination?.totalItems} {declension} из {category?.items?.products?.pagination?.totalItems}</p>
                            : <p>Показано 36 предложений из {category?.items?.products?.pagination?.totalItems}</p>}
                        <select defaultValue={false} value={sort} onChange={(e) => setSort(e.target.value)}>
                            <option value="false" disabled>Сортировать:</option>
                            <option value="new">по дате публикации: новые</option>
                            <option value="old">по дате публикации: ранние</option>
                        </select>
                    </nav>

                    <Row className=" g-2 g-sm-3 g-xl-4 mb-4">
                        {category?.items?.products?.items && category?.items?.products?.items?.length > 0 ? category?.items?.products?.items.map(item => (
                            item?.placement ?
                                <Col xs={12}>
                                    <Link to={item?.link} target='_blank' className='card-carousel'>
                                        <img src={
                                            getImageURL({
                                                path: item.media,
                                                type: "banner",
                                                size: "full",
                                            })
                                        }
                                            alt={item.title}
                                        />
                                    </Link>
                                </Col>
                                :
                                <Col xs={6} md={4} xl={3}><AdvCard {...item} onFav={onFav} /></Col>

                        )
                        )
                            :
                            <Col xs={12} className='align-item-center text-center'>
                                <h3 className='text-center'>Нет обьявлений</h3>
                            </Col>

                        }



                    </Row>

                    <nav className="catalog-nav mb-0">
                        <NavPagination totalPages={category?.items?.products?.pagination?.totalPages} onPageChange={onPageChange} currentPage={currentPage} />
                        {category?.items?.products?.pagination?.totalItems <= 36 ? <p>Показано {category?.items?.products?.pagination?.totalItems} {declension} из {category?.items?.products?.pagination?.totalItems}</p>
                            : <p>Показано 36 предложений из {category?.items?.products?.pagination?.totalItems}</p>}
                        <select defaultValue={false} value={sort} onChange={(e) => setSort(e.target.value)}>
                            <option value="false" disabled>Сортировать:</option>
                            <option value="new">по дате публикации: новые</option>
                            <option value="old">по дате публикации: ранние</option>
                        </select>
                    </nav>
                </section>

                <BlogSection articles={articles} />
            </Container>
        </main >
    )
}

export default Catalog