import React, { useCallback } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import Plaix from './svg/Plaix';

import useIsMobile from '../hooks/isMobile';


import { useForm, useWatch } from 'react-hook-form';
import { Md3P, MdHome, MdMenu, MdOutlineFactCheck, MdOutlineStarOutline } from "react-icons/md";
import { SlSocialFacebook, SlSocialInstagram, SlSocialVkontakte } from "react-icons/sl";
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';
import Logo from "../assets/imgs/logo-bml.svg";
import { createTaskAny } from '../services/task';
import { FaTelegram } from 'react-icons/fa';


const Footer = ({ categories }) => {
  const isMobileLG = useIsMobile('991px');
  const userId = useSelector(state => state.auth?.user?.id);
  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: userId,
  });
  const data = useWatch({ control });
  const onTask = useCallback(() => {
    createTaskAny({ type: "task", ...data, title: "Подписался на рассылку" })
      .then(() => {
        NotificationManager.success("Email отправлен");

      })
      .catch((err) => {
        NotificationManager.error(
          err?.response?.data?.error ?? "Ошибка при отправке"
        );
      });
  }, [data]);
  return (
    <>
      <footer>
        <Container className='h-100'>
          {
            (isMobileLG)
              ? <nav className='nav-mobile'>
                <ul>
                  <li>
                    <Link to='/'>
                      <MdHome />
                      <span>Главная</span>
                    </Link>
                  </li>
                  <li>
                    <Link to='/account/favs'>
                      <MdOutlineStarOutline />
                      <span>Избранное</span>
                    </Link>
                  </li>
                  <li>
                    <Link to='/account/offers' end>
                      <MdOutlineFactCheck />
                      <span>Объявления</span>
                    </Link>
                  </li>
                  <li>
                    <Link to='/account/messages' end>
                      <Md3P />
                      <span>Сообщения</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/account/" type="button">
                      <MdMenu />
                      <span>Меню</span>
                    </Link>
                  </li>
                </ul>
              </nav>
              : <Row className='h-100 align-items-xl-center'>
                <Col xs={12} sm={6} md={4} lg={3} className="mb-4 mb-sm-0 ">
                  <Link to="/">
                    <img src={Logo} alt="БИЗНЕС MY LIFE" className="logo img-fluid" />
                  </Link>

                  <p className='mt-3 mb-2'>МЫ В СОЦИАЛЬНЫХ СЕТЯХ</p>
                  <ul className='list-unstyled footer-social'>

                    <li>
                      {/* <a href="https://vk.com/" target="_blank" rel="noreferrer"> */}
                      <SlSocialVkontakte />
                      {/* </a> */}
                    </li>
                    <li>
                      {/* <a href="https://vk.com/" target="_blank" rel="noreferrer"> */}
                      <FaTelegram />
                      {/* </a> */}
                    </li>

                  </ul>
                </Col>

                <Col xs={12} sm={6} md={8} lg={9}>
                  <Row >
                    <Col xs={7} xl={6}>
                      <ul className="footer-menu">
                        {categories?.items?.length > 0 && categories?.items?.map(category =>
                          <li key={category.id}>
                            <Link to={'/catalog/' + category.id}>{category.title.toUpperCase()}</Link>
                          </li>
                        )}
                        <li><Link to="/contacts">КОНТАКТЫ</Link></li>
                        <li><Link to="/blog">НОВОСТИ</Link></li>
                        <li><Link to="/info">ИНФОРМАЦИЯ ПО САЙТУ</Link></li>
                      </ul>
                    </Col>
                    <Col xs={5} xl={6}>
                      <p className='mb-3'>ПОДПИШИТЕСЬ НА НОВОСТИ: ВЫГОДНЫЕ БИЗНЕС ПРЕДЛОЖЕНИЯ, ЛУЧШИЕ ФРАНШИЗЫ И ПРОЕКТЫ</p>
                      <form action="" className='subscribe'>
                        <div className="d-flex">
                          <input type="email" placeholder='Введите e-mail' onChange={e => setValue("comment", e.target.value)} />
                          <button type='submit' className='btn-secondary' disabled={!isValid} onClick={handleSubmit(onTask)}>ок</button>
                        </div>
                        <label className="d-flex mt-3">
                          <input type="checkbox" defaultChecked={true}   {...register("accept")} />
                          <span className='flex-1 ms-2'>Согласен с правилами предоставления услуг</span>
                        </label>
                      </form>
                    </Col>

                  </Row>
                </Col>
                <Col md={12}>
                  <a href="https://plaix.ru/" target='_blank' className='dev-link ms-auto'>
                    <span>Создано в</span>
                    <Plaix />
                  </a>
                </Col>
              </Row>
          }
        </Container>
      </footer>


    </>
  );
};

export default Footer;