import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import AppLayout from "../layouts/AppLayout";
import Home from "../pages/Home";
import NotFound from "../pages/NotFound";
import Offer from "../pages/Offer";

import AuthRoute from "../layouts/AuthRoute";
import Article from "../pages/Article";
import Activate from "../pages/auth/Activate";
import Login from "../pages/auth/Login";
import Recovery from "../pages/auth/Recovery";
import Registration from "../pages/auth/Registration";
import Blog from "../pages/Blog";
import Catalog from "../pages/Catalog";
import Contacts from "../pages/Contacts";
import ViewProfile from "../pages/ViewProfile";
import AccountRouter from "./AccountRouter";
import Info from "../pages/Info";
import InfoBlog from "../pages/InfoBlog";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<AppLayout />}>
      <Route index element={<Home />} />
      <Route path="catalog/:id" element={<Catalog />} />
      <Route path="offer/:id" element={<Offer />} />
      <Route path="blog" element={<Blog />} />
      <Route path="blog/:id" element={<Article />} />
      <Route path="contacts" element={<Contacts />} />
      <Route path="info" element={<Info />} />
      <Route path="info/:id" element={<InfoBlog />} />
      <Route path="registration" element={<Registration />} />
      <Route path="password" element={<Recovery />} />
      <Route path="login" element={<Login />} />
      <Route path="activate" element={<Activate />} />
      <Route path="user/:id" element={<ViewProfile />} />
      <Route
        path="account/*"
        element={
          <AuthRoute>
            <AccountRouter />
          </AuthRoute>
        }
      />

      {/* 
      <Route path="game/lot/:lotId" element={<LotPage />} />
      <Route path="profile/:userId" element={<Profile />} />

      
     
      <Route
        path="account/*"
        element={
          <AuthRoute>
            <AccountRouter />
          </AuthRoute>
        }
      />
      <Route path="activate-email/:key" element={<ActivateEditEmail />} />
      <Route path="activate/:key" element={<Activate />} />
      <Route path="help" element={<FAQ />} />
      <Route path="help/:id" element={<QuestionPage />} />
      <Route path="privacy" element={<PrivacyPolicy />} />
      <Route path="rules" element={<Rules />} />
      <Route path="cookie" element={<Cookie />} />
      <Route path="feedback" element={<Feedback />} /> */}
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);

const AppRouter = () => {
  return <RouterProvider router={router} />;
};

export default AppRouter;
