import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AdvCard from "../components/AdvCard";
import BlogSection from "../components/BlogSection";
import InfoInNums from "../components/InfoInNums";
import MainSlider from "../components/MainSlider";
import Meta from "../components/Meta";
import SecPartners from "../components/SecPartners";
import Loader from "../components/utils/Loader";
import { getArticles } from "../services/article";
import { getCategories } from "../services/category";
import { getFavorites, toggleFavorite } from "../services/favorite";
import { getPartner } from "../services/partner";
import { getSales } from "../services/sales";

const Home = () => {
  const [sales, setSales] = useState({
    loading: true,
    items: [],
  });

  const isAuth = useSelector((state) => state?.auth.isAuth);
  const [categories, setCategories] = useState({ items: [], data: [], loading: true });

  const [articles, setArticles] = useState({
    loading: true,
    items: [],
  });
  const [partner, setPartner] = useState({
    loading: true,
    items: [],
  });

  useEffect(() => {
    setSales((prev) => ({ ...prev, loading: true }))
    setCategories((prev) => ({ ...prev, loading: true }))
    setArticles((prev) => ({ ...prev, loading: true }))
    setPartner((prev) => ({ ...prev, loading: true }))
    getSales()
      .then(
        (res) =>
          res &&
          setSales((prev) => ({
            ...prev,
            loading: false,
            ...res,
          }))
      )
      .catch(() => setSales((prev) => ({ ...prev, loading: false })));


    getCategories()
      .then((res) => {
        setCategories(prev => ({ ...prev, items: res?.categories?.sort((a, b) => a.commission - b.commission), loading: false }));
      })
      .catch(() => setCategories((prev) => ({ ...prev, loading: false })));

    getArticles({ size: 4 })
      .then((res) => {
        setArticles((prev) => ({
          prev,
          loading: false,
          ...res,
        }))
      })
      .catch(() => setArticles((prev) => ({ ...prev, loading: false })));

    getPartner()
      .then(
        (res) =>
          res &&
          setPartner((prev) => ({
            ...prev,
            loading: false,
            ...res,
          }))
      )
      .catch(() => setPartner((prev) => ({ ...prev, loading: false })));

  }, []);
  const favorites = useSelector((state) => state.favorite.items);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isAuth) {
      dispatch(getFavorites());
    }
  }, []);

  const onFav = useCallback((e) => {
    dispatch(toggleFavorite({ productId: e, }));
  }, []);
  if (categories.loading || articles.loading || sales.loading || partner.loading) {
    return <Loader full />;
  }
  return (
    <main>
      <Meta title="Бизнес My Life - Поиск бизнес партнёров и инвестиций" />

      <MainSlider sales={sales} />

      <InfoInNums />

      <section className="sec-about mb-6">
        <Container>
          <Row className="gx-xxl-5 align-items-center">
            <Col xs={12} md={4} className="mb-0 mb-md-0">
              <ReactPlayer
                url="https://www.youtube.com/embed/Kj0rRv5Xz_4?si=8CdkXBCj3I3wUjKX"
                width="100%"
                height={240}
                controls={true}
              />
            </Col>
            <Col xs={12} md={8} xl={6}>
              <h1>Немного о нас "Business My Life"</h1>
              <p className="fs-11">Наша бизнес платформа представляет собой сайт бесплатных объявлений по поиску инвесторов, бизнес партнеров, продажи готового бизнеса и франшиз.</p>
              <p className="fs-11">Наша задача объединить как начинающих так и опытных предпринимателей со всей страны для создания новых и уникальных продуктов в разных отраслях бизнеса.</p>
              <p className="fs-11">Присоединяйтесь к тысячной армии предпринимателей, с уважением, команда "Бизнес My Life".</p>
            </Col>
          </Row>
        </Container>
      </section>

      <Container>
        {categories?.items && categories?.items.length > 0 && categories.items.map((item, index) =>
          <section className="mb-6" key={index}>
            <h2>{item.title.toUpperCase()}</h2>
            <Row xs={2} md={3} xl={4} className="g-2 g-sm-3 g-xl-4">
              {item?.products && item.products?.length > 0 && item.products.map(product =>
                <Col key={product?.id}>
                  <AdvCard key={product?.id} {...product} onFav={onFav} />
                </Col>
              )}
            </Row>
            <Link to={'/catalog/' + item.id} className="btn-primary mt-4 mx-auto">Показать еще</Link>
          </section>
        )}
        {articles?.items && articles?.items.length > 0 && <BlogSection articles={articles} />}
      </Container>

      <SecPartners partner={partner} />
    </main >
  );
};

export default Home;
