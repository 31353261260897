import React from 'react';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import useIsMobile from '../hooks/isMobile';
import { getImageURL } from '../helpers/all';

const BlogInfo = ({ id, media, title, content }) => {
  const isMobileLG = useIsMobile('1109px');
  return (
    <Link to={`/blog/${id}`}>
      <figure className="article-midi">
        <ReactPlayer
          url={getImageURL({ path: media, size: "", type: "instruction" })}
          width="100%"
          height={200}
          controls={true}
        />
        <figcaption>
          <h6>{title}</h6>
          <div className="article-midi-text" dangerouslySetInnerHTML={{ __html: content }}>
          </div>
        </figcaption>
      </figure>
    </Link>
  );
};

export default BlogInfo;